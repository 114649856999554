import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import ReactMarkdown from "react-markdown"

const SpecialisatiesPage = ({ data }) => (
  <Layout>
    <SEO title={data.strapiSpecialisaties.meta_titel + " | Specialisaties"} description={data.strapiSpecialisaties.meta_omschrijving} lang="nl" />
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>{data.strapiSpecialisaties.titel}</h1>
        </div>
        <div className="col-lg-9">
          <ReactMarkdown source={data.strapiSpecialisaties.tekst} />
        </div>
      </div>
    </div>
    <div className="specialisaties specialisaties-page">
      <div className="container">
        <div className="row">
          {data.allStrapiBehandeling.edges.map((document, index) =>(
            <div className="col-lg-4 mb-3 specialisatie-col" key={document.node.id}>
              <div className={"specialisatie d-md-flex d-lg-block specialisatie-" + index}>
                <div className="specialisatie-afb mr-md-3 mr-lg-0">
                  <Link to={`/specialisaties/${document.node.titel.replace(/\s/g, "").toLowerCase()}`}>
                    {document.node.afbeeldingen.sort((a, b) => (a.name > b.name) ? 1 : -1).map((afb, i) =>(
                        <div key={afb.id}>
                          {i < 1 &&
                            <img src={afb.url} alt={afb.alternativeText} className="mb-3 mb-md-0 mb-lg-3" />
                          }
                        </div>
                      ))}
                  </Link>
                </div>
                <div className="specialisatie-intro text-center text-md-left text-lg-center">
                  <h3>{document.node.titel}</h3>
                  <ReactMarkdown source={document.node.intro} />
                  <Link to={`/specialisaties/${document.node.titel.replace(/\s/g, "").toLowerCase()}`}>
                    <span className="btn btn-outline-primary">Lees meer</span>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Layout>
)


export default SpecialisatiesPage

export const pageQuery = graphql`
  query SpecialisatiesQuery {
    allStrapiBehandeling {
      edges {
        node {
          id
          titel
          intro
          afbeeldingen {
            alternativeText
            id
            name
            url
          }
        }
      }
    }
    strapiSpecialisaties {
        titel
        tekst
        meta_titel
        meta_omschrijving
      }
  }
`